/* eslint-disable no-console */
import { HttpStatusCode } from '@/data/protocols/http'
import { AddressPageForms, Level } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { AddressSavePageForms } from '@/data/use-cases/register-maintenance/remote-save-address'
import { ADDRESS_STORAGE_NAME, AddressStorage, SEND_CODE_VALIDATION_ORIGIN } from '@/domain/models/address'
import { ADDRESS_DELIVERY_TYPE } from '@/domain/models/address/address-delivery-type'
import { LevelName } from '@/domain/models/address/address-levels'
import { MaintenanceAddressMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { validateStructureCodeSegmentation } from '@/domain/models/tenant/tenant-configs/helpers/validate-structure-code-segmentation'
import {
  ControlledAddressNumberField,
  ControlledSelect,
  ControlledTextField,
  GeoCommercialConfig,
  GeoCommercialStructure,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import Alert from '@naturacosmeticos/natds-react/dist/components/Alert'
import { GridContainer, GridItem, Icon, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Control, FormProvider, useForm, useFormContext } from 'react-hook-form'

import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { redirectToHome, redirectToProfile } from '@/domain/models/tenant/tenant-configs/helpers/redirect'

import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'

import { getObjectInStorage, removeItemInStorage, setObjectInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'

import verifyConsultantIdPersonId from '@/utils/verifyConsultantIdPersonId'

import {
  SendCodeValidationOrigin,
  SendCodeValidationStorage,
} from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { CountryId } from '@/domain/models/country'
import { capitalizeWords } from '@/utils/capitalize-words'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Helmet } from 'react-helmet'
import { AddressMessages } from '../address/models'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { MaintenanceDialog } from '../register-maintenance/fields/maintenance-dialog'
import { RegisterMaintenanceAddressPageApi } from './api/make-register-maintenance-address-page-api'
import { Container } from './components/pages-structures'
import { ControlledTextFieldWithLink } from './components/text-field-with-link/controlled-text-field-with-link'
import { AlertContainer, StreetExample, useMaintenanceAddressPage } from './maintenance-address-page.styles'

export type MaintenanceAddressPageProps = {
  api: RegisterMaintenanceAddressPageApi
}

type DialogState = {
  message?: string
  isOpen: boolean
  confirmButtonName?: string
  cancelButtonName?: string
  confirmationButtonCallback?: () => void
  cancelButtonCallback?: () => void
  component?
}

type AddressPeru = {
  level1: Level
  level2: Level
  level3: Level
  level4: Level
  complement: string
  number: string
  urbanizacion: string
  references: string
  street: string
  state?: string
  city?: string
  zipCode?: string
  neighborhood?: string
}

type Address = {
  city: string
  complement: string
  neighborhood: string
  number: string
  references: string
  state: string
  zipCode: string
  street: string

  level1?: Level
  level2?: Level
  level3?: Level
  level4?: Level
  urbanizacion?: string
}

type FormAddress = Address | AddressPeru

type AddressFieldStatus = {
  isStreetDisabled: boolean
  isNeighborhoodDisabled: boolean
}

export const MaintenanceAddressPage: React.FC<MaintenanceAddressPageProps> = ({ api }) => {
  const { personId, countryId, companyId, businessModel, consultantId, isCN, sourceSystem } = useContext(
    IdentityInfoContext
  )

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const componentClasses = useMaintenanceAddressPage()

  const [deliveryTypeLabel, setDeliveryTypeLabel] = useState<string>('')

  const isPeru = countryId === CountryId.Peru

  const createMessageOptions = (label) => ({ addressDeliveryType: label })
  const createMessageOptionsAddress = ({
    maximumCharactersForRoad = undefined,
    maximumCharactersForReferences = undefined,
    maximumCharactersForNeighborhood = undefined,
    maximumCharactersForNumber = undefined,
    maximumCharactersForComplement = undefined,
  }) => ({
    maximumCharactersForRoad,
    maximumCharactersForReferences,
    maximumCharactersForNeighborhood,
    maximumCharactersForNumber,
    maximumCharactersForComplement,
  })

  const maintenanceAddressMessages = usePageMessages(
    Page.MaintenanceAddress,
    createMessageOptions(deliveryTypeLabel)
  ).messages as MaintenanceAddressMessages

  const formMethods = useForm<FormAddress>({
    mode: 'onChange',
  })

  const [shouldNotResetOnce, setShouldNotResetOnce] = useState(false)
  const [addressModalStatus, setAddressModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [openLoadingSpinner, setOpenLoadingSpinner] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [savedAddress, setSavedAddress] = useState<AddressPageForms>()
  const [receiveBillingAtSameAddress, setReceiveBillingAtSameAddress] = useState<boolean>(false)
  const [addressFieldsStatus, setAddressFieldsStatus] = useState<AddressFieldStatus>({
    isStreetDisabled: false,
    isNeighborhoodDisabled: false,
  })
  const mustResetLevels = useRef(false)

  const { zipCode, number, neighborhood, street, reference, complement } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const addressMessages = usePageMessages(
    Page.Address,
    createMessageOptionsAddress({
      maximumCharactersForRoad: street?.maxLength,
      maximumCharactersForReferences: reference?.maxLength,
      maximumCharactersForNeighborhood: neighborhood?.rules?.maxLength,
      maximumCharactersForNumber: number?.rules?.maxLength,
      maximumCharactersForComplement: complement?.rules?.maxLength,
    })
  ).messages as AddressMessages

  const formRules = { required: addressMessages.addressRequiredError }

  const {
    getValues,
    control,
    reset,
    setError,
    formState: { isValid, isDirty },
  } = formMethods

  const getDeliveryTypeAddress = (deliveryType: string) => {
    let deliveryName

    switch (ADDRESS_DELIVERY_TYPE[deliveryType]) {
      case ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.delivery
        break
      case ADDRESS_DELIVERY_TYPE.MAILING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.mailing
        break
      case ADDRESS_DELIVERY_TYPE.BILLING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.billing
        break
      default:
        deliveryName = ''
    }

    return deliveryName
  }

  useEffect(() => {
    if (!validateStructureCodeSegmentation()) {
      redirectToProfile(personId, null)
    }

    if (isCN && !verifyConsultantIdPersonId(personId, consultantId) && sourceSystem !== '50') {
      redirectToHome()
    }
    setAddressModalStatus({
      isOpen: true,
      component: () => leaderChangeAlert(componentClasses, maintenanceAddressMessages.leaderDialog),
      confirmButtonName: maintenanceAddressMessages.leaderDialog.confirmButton,
      cancelButtonName: maintenanceAddressMessages.leaderDialog.cancelButton,
      confirmationButtonCallback: onCloseWithoutPreviousPage,
      cancelButtonCallback: onClose,
    })
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, companyId, countryId, personId, reset])

  const getAddressStorage = () => {
    const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage
    if (addressStorage && addressStorage?.personId === consultantId) {
      return { ...addressStorage.address }
    }

    removeItemInStorage(ADDRESS_STORAGE_NAME)
    return {}
  }

  const addressFormatGeoLevels = (address: any): Record<string, Level> => {
    const geoLevels = ['level1', 'level2', 'level3', 'level4']

    const addressGeoLevelsFormated = geoLevels.reduce((acc, levelSelected) => {
      acc[levelSelected] = {
        label: capitalizeWords(address[levelSelected]?.label),
        value: address[levelSelected]?.value,
      }
      return acc
    }, {} as Record<string, Level>)

    return addressGeoLevelsFormated
  }

  const loadAddressApi = async () => {
    setOpenLoadingSpinner(true)
    api
      .getMultipleAddresses({
        personId,
        countryId,
        companyId,
        requesterPersonId: personId,
        formatAllLevels: true,
      })
      .then(({ deliveryAddress }) => {
        const addressStorage = getAddressStorage()
        const address = { ...deliveryAddress, ...addressStorage }
        const addressGeoLevelsFormated = addressFormatGeoLevels(address)
        setDeliveryTypeLabel(getDeliveryTypeAddress(ADDRESS_DELIVERY_TYPE[address.addressUse]))
        setSavedAddress(address)

        const resetData = {
          city: address?.city,
          complement: address?.complement,
          neighborhood: address?.neighborhood,
          number: address?.number || maintenanceAddressMessages.withoutNumber,
          references: address?.references,
          state: address?.state,
          street: address?.street,
          zipCode: address?.zipCode?.replace(zipCode.regex, zipCode.regexOption),
          level1: addressGeoLevelsFormated?.level1,
          level2: addressGeoLevelsFormated?.level2,
          level3: addressGeoLevelsFormated?.level3,
          level4: addressGeoLevelsFormated?.level4 as Level,
          urbanizacion: address?.urbanizacion,
        }

        setTimeout(() => {
          reset(resetData)
        }, 200)

        setShouldNotResetOnce(true)

        setTimeout(() => {
          mustResetLevels.current = true
        }, 200)

        if (isPeru) return

        api
          .getAddressByZipCode({
            businessModel,
            code: Number(getOnlyNumbers(address.zipCode)),
            tenantId,
          })
          .then((addressResponse) => {
            setAddressFieldsStatus({
              isStreetDisabled: addressResponse.street !== '',
              isNeighborhoodDisabled: addressResponse.neighborhood !== '',
            })
          })
          .catch((error) => {
            handleDataCareException(error)
          })
          .finally(() => {
            setOpenLoadingSpinner(false)
          })
      })
      .catch((...args) => {
        if (args.some((arg) => arg.statusCode === HttpStatusCode.unauthorized)) {
          handleGoBackToList()
        }
        console.warn('Error on get addresses')
      })
      .finally(() => {
        setOpenLoadingSpinner(false)
      })
  }
  const handleGoBackToList = () => {
    const urlList = createUrlAddressMaintenance({
      url: 'list',
      country: countryId,
    })

    window.location.assign(urlList)
  }

  const handleGetAddressByZipCode = async (zipCodeValue) => {
    const isValidZipCodeLength = getOnlyNumbers(zipCodeValue)?.length === zipCode.rules.maxLength - 1

    if (isValidZipCodeLength) {
      setOpenLoadingSpinner(true)
      try {
        const addressResponse = await api.getAddressByZipCode({
          businessModel,
          code: Number(getOnlyNumbers(zipCodeValue)),
          tenantId,
        })

        setAddressFieldsStatus({
          isStreetDisabled: addressResponse.street !== '',
          isNeighborhoodDisabled: addressResponse.neighborhood !== '',
        })

        reset({
          city: addressResponse.city,
          neighborhood: addressResponse.neighborhood,
          state: addressResponse.state,
          zipCode: zipCodeValue,
          street: addressResponse.street,
          references: '',
          number: '',
          complement: '',
        })
      } catch (error) {
        setError(LevelName.ZipCode, {
          type: 'error',
          message: maintenanceAddressMessages.errorLabel.wrongZipcodeError,
        })
      } finally {
        setOpenLoadingSpinner(false)
      }
    } else {
      setError(LevelName.ZipCode, {
        type: 'error',
        message: maintenanceAddressMessages.errorLabel.wrongZipcodeError,
      })
    }
  }

  const handleDataCareException = (error) => {
    if (error.statusCode === HttpStatusCode.notFound) {
      handleNotFoundZipCode()
    } else {
      setAddressModalStatus({
        message: actionMessages.unexpectedErrorDialogMessage,
        isOpen: true,
        confirmationButtonCallback: onCloseWithoutPreviousPage,
        confirmButtonName: actionMessages.dialogButtonName,
      })
    }
  }

  const handleNotFoundZipCode = () => {
    reset({
      ...getValues(),
      city: '',
      neighborhood: '',
      state: '',
      street: '',
      references: '',
      number: '',
      complement: '',
    })

    setError(LevelName.ZipCode, { type: 'error', message: addressMessages.zipCode.notFoundZipCode })
  }

  const actionMessages = {
    saveButton: maintenanceAddressMessages.labels.saveButton,
    previousButton: maintenanceAddressMessages.labels.previousButton,
    dialogButtonName: maintenanceAddressMessages.dialog.buttonName,
    unexpectedErrorDialogMessage: maintenanceAddressMessages.dialog.unexpectedErrorMessage,
    successDialogMessage: maintenanceAddressMessages.dialog.successMessage,
  }

  const goBackPreviousPage = () => window.history.back()

  const onClose = () => {
    setAddressModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const onCloseWithoutPreviousPage = () => {
    loadAddressApi()
    setAddressModalStatus({ isOpen: false })
  }

  const mapToAddressPageForms = (address: FormAddress): AddressSavePageForms => {
    if (isPeru) {
      return {
        addressType: savedAddress?.addressType,
        addressUse: savedAddress?.addressUse,
        complement: address.complement,
        country: savedAddress?.country,
        level1: address.level1,
        level2: address.level2,
        level3: address.level3,
        level4: address.level4,
        neighborhood: address.neighborhood,
        number: address.number,
        receiveBillingAtSameAddress,
        references: address.references,
        sequence: savedAddress?.sequence,
        street: address.street,
        urbanizacion: address.urbanizacion,
      }
    }

    return {
      city: address.city,
      complement: address.complement,
      country: savedAddress.country,
      number: address.number,
      neighborhood: address.neighborhood,
      references: address.references,
      state: address.state,
      street: address.street,
      zipCode: getOnlyNumbers(address.zipCode),
      addressType: savedAddress.addressType,
      addressUse: savedAddress.addressUse,
      sequence: savedAddress.sequence,
      receiveBillingAtSameAddress,
    }
  }

  const handleSubmit = () => {
    const addressStorage = {
      consultantId,
      address: mapToAddressPageForms(getValues()),
    }

    const sendCodeValidationOrigin: SendCodeValidationStorage = {
      origin: SendCodeValidationOrigin.CHANGE_ADDRESS,
    }

    setObjectInStorage(ADDRESS_STORAGE_NAME, addressStorage)
    setObjectInStorage(SEND_CODE_VALIDATION_ORIGIN, sendCodeValidationOrigin)

    const url = createUrlAddressMaintenance({
      url: `mfe-register/maintenanceSecurityValidation/${consultantId}`,
      country: countryId,
    })

    window.location.assign(url)
  }

  return (
    <Container
      nextButtonLabel={actionMessages.saveButton}
      disableNextButton={!isValid || !isDirty}
      onNextButtonClick={handleSubmit}
      previousButtonLabel={actionMessages.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      nextButtonTextInline
      isLoading={isLoading}
    >
      <Helmet>
        <title>{maintenanceAddressMessages.address}</title>
      </Helmet>
      <div className={componentClasses.container}>
        <Typography variant="h6" color="textPrimary" className={componentClasses.title}>
          <span data-testid={deliveryTypeLabel}>{maintenanceAddressMessages.address}</span>
        </Typography>
        <FormProvider {...formMethods}>
          {!isPeru && (
            <>
              {zipCode && (
                <ControlledTextFieldWithLink
                  id={LevelName.ZipCode}
                  label={addressMessages.zipCode.label}
                  placeholder={addressMessages.zipCode.placeholder}
                  linkText={
                    zipCode.shouldDisplayLink({ authenticated: true })
                      ? addressMessages.zipCode.link.text
                      : undefined
                  }
                  href={
                    zipCode.shouldDisplayLink({ authenticated: true })
                      ? addressMessages.zipCode.link.url
                      : undefined
                  }
                  control={control}
                  customOnChange={handleGetAddressByZipCode}
                  mask={zipCode.mask}
                  rules={{
                    minLength: {
                      message: addressMessages.zipCode.errorMessage,
                      value: zipCode.rules.maxLength,
                    },
                    maxLength: {
                      message: addressMessages.zipCode.errorMessage,
                      value: zipCode.rules.maxLength,
                    },
                    required: {
                      value: true,
                      message: addressMessages.zipCode.requiredErrorMessage,
                    },
                  }}
                  required
                  showAsterisk
                />
              )}
              <ControlledTextField
                id={LevelName.Street}
                label={addressMessages.mainStreet.label}
                control={control}
                rules={{
                  ...formRules,
                  maxLength: {
                    message: addressMessages.mainStreet.errorMessage,
                    value: street?.maxLength,
                  },
                }}
                placeholder={addressMessages.placeholders.mainStreet}
                required
                disabled={addressFieldsStatus.isStreetDisabled}
                shouldValidateIsNotOnlySpaces
                validChars={street?.validChars}
              />
              {!addressFieldsStatus.isStreetDisabled && (
                <StreetExample>
                  <Typography variant="caption" className={componentClasses.streetExample}>
                    {maintenanceAddressMessages.streetExample}
                  </Typography>
                </StreetExample>
              )}
              <GridContainer className="natds1" justify="space-between" spacing={3}>
                <GridItem className="natds2" md={6} sm={6} xs={6}>
                  <div className={componentClasses.addressNumberField}>
                    <ControlledAddressNumberField
                      id={LevelName.Number}
                      label={addressMessages.number.label}
                      placeholder={addressMessages.placeholders.number}
                      rules={{
                        required: {
                          message: addressMessages.addressRequiredError,
                          value: true,
                        },
                        maxLength: {
                          message: addressMessages.number.errorMessage,
                          value: number?.rules?.maxLength,
                        },
                      }}
                      noNumberLabel={addressMessages.checkBoxWithoutNumber}
                      validChars={number && number.validChars}
                      hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
                    />
                  </div>
                </GridItem>
                <GridItem className="natds2" md={6} sm={6} xs={6}>
                  <ControlledTextField
                    id={LevelName.Complement}
                    label={addressMessages.complementaryData.label}
                    control={control}
                    placeholder={addressMessages.placeholders.complementaryData}
                    noValueCheckbox
                    noValueLabel={addressMessages.checkBoxWithoutComplementaryData}
                    required
                    rules={{
                      ...formRules,
                      maxLength: {
                        message: addressMessages.complementaryData.errorMessage,
                        value: complement?.rules?.maxLength,
                      },
                    }}
                    validChars={complement?.validChars}
                  />
                </GridItem>
              </GridContainer>
              <ControlledTextField
                id={LevelName.Neighborhood}
                label={addressMessages.neighborhood.label}
                control={control}
                rules={{
                  ...formRules,
                  maxLength: {
                    message: addressMessages.neighborhood.errorMessage,
                    value: neighborhood?.rules?.maxLength,
                  },
                }}
                placeholder={addressMessages.placeholders.neighborhood}
                required
                shouldValidateIsNotOnlySpaces
                disabled={addressFieldsStatus.isNeighborhoodDisabled}
                validChars={neighborhood?.validChars}
              />
              <GridContainer className="natds1" justify="space-between" spacing={3}>
                <GridItem className="natds2" md={6} sm={6} xs={6}>
                  <ControlledTextField
                    id={LevelName.City}
                    label={addressMessages.city}
                    control={control}
                    rules={formRules}
                    placeholder={addressMessages.placeholders.city}
                    required
                    shouldValidateIsNotOnlySpaces
                    disabled
                  />
                </GridItem>
                <GridItem className="natds2" md={6} sm={6} xs={6}>
                  <ControlledTextField
                    id={LevelName.State}
                    label={addressMessages.state}
                    control={control}
                    rules={formRules}
                    placeholder={addressMessages.placeholders.state}
                    required
                    shouldValidateIsNotOnlySpaces
                    disabled
                  />
                </GridItem>
              </GridContainer>
              <ControlledTextField
                id={LevelName.References}
                label={addressMessages.references.label}
                control={control}
                rules={{
                  ...formRules,
                  maxLength: {
                    message: addressMessages.references.errorMessage,
                    value: reference?.maxLength,
                  },
                }}
                placeholder={addressMessages.placeholders.references}
                required
                shouldValidateIsNotOnlySpaces
                validChars={reference?.validChars}
              />
              <div className={componentClasses.displayNone}>
                <ControlledSelect
                  id={LevelName.addressDeliveryType}
                  label={maintenanceAddressMessages.labels.addressDeliveryType}
                  aria-label={maintenanceAddressMessages.labels.addressDeliveryType}
                  rules={formRules}
                  control={control}
                  options={[
                    {
                      label: addressMessages.addressDeliveryType.delivery,
                      value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS,
                    },
                  ]}
                  disabled
                  defaultValue={{
                    label: addressMessages.addressDeliveryType.delivery,
                    value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS,
                  }}
                  hasAsteriskInTheLabel
                />
              </div>
              <div className={componentClasses.displayNone}>
                <Checkbox
                  color="secondary"
                  label={addressMessages.confirmDeliveryAddressAsResidenceAddress}
                  checked={receiveBillingAtSameAddress}
                  onChange={(e) => setReceiveBillingAtSameAddress(e.target.checked)}
                />
              </div>
            </>
          )}

          {isPeru && (
            <AddressPeruForm
              shouldNotResetOnce={shouldNotResetOnce}
              control={control}
              mustResetLevels={mustResetLevels.current}
            />
          )}
        </FormProvider>
        <AlertContainer data-testid="alert-container">
          <Alert
            title={maintenanceAddressMessages.alert.title}
            color="warning"
            type="outlined"
            data-testid="alert-component"
          >
            <Typography
              variant="body2"
              data-testid="alert-subtitle-one"
              className={componentClasses.alertSpace}
            >
              {maintenanceAddressMessages.alert.subtitleOne}
            </Typography>
            <Typography variant="body2" className={componentClasses.alertSpace}>
              {maintenanceAddressMessages.alert.subtitleTwo}
            </Typography>
          </Alert>
        </AlertContainer>
        <MaintenanceDialog
          message={addressModalStatus.message}
          confirmButtonName={addressModalStatus.confirmButtonName}
          cancelButtonName={addressModalStatus.cancelButtonName}
          open={addressModalStatus.isOpen}
          onClickConfirmButton={addressModalStatus.confirmationButtonCallback}
          onClickCancelButton={addressModalStatus.cancelButtonCallback}
          Children={addressModalStatus.component}
        />
        <DialogWithLoader isOpen={openLoadingSpinner} />
      </div>
    </Container>
  )
}

const leaderChangeAlert = (componentClasses, messages) => (
  <div className={componentClasses.dialogLeader}>
    <Typography variant="h6" className={componentClasses.dialogLeaderTitle}>
      {messages.title}
    </Typography>
    <span className={componentClasses.dialogLeaderItem}>
      <div className={componentClasses.dialogLeaderIconPeru}>
        <Icon
          data-testid="status-icon"
          name="outlined-alert-warning"
          size="tiny"
          className={componentClasses.dialogLeaderIcon}
        />
      </div>
      <Typography variant="subtitle1">{messages.alertRequestProcess}</Typography>
    </span>
    <span className={componentClasses.dialogLeaderItem}>
      <div className={componentClasses.dialogLeaderIconPeru} style={{ paddingTop: '3px' }}>
        <Icon
          data-testid="status-icon"
          name="outlined-action-request"
          size="tiny"
          className={componentClasses.dialogLeaderIcon}
        />
      </div>
      <Typography variant="subtitle1">{messages.leaderChange}</Typography>
    </span>
    <Typography variant="subtitle1">{messages.confirmation}</Typography>
  </div>
)

export const AddressPeruForm = ({
  shouldNotResetOnce,
  control,
  mustResetLevels,
}: {
  shouldNotResetOnce: boolean
  mustResetLevels: boolean
  control: Control<FormAddress>
}) => {
  const { watch, reset } = useFormContext()
  const { countryId, companyId } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const { number, street, reference, complement, neighborhood } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs
  const addressMessages = usePageMessages(Page.Address, {
    maximumCharactersForRoad: street?.maxLength,
    maximumCharactersForReferences: reference?.maxLength,
    maximumCharactersForNeighborhood: neighborhood?.rules?.maxLength,
    maximumCharactersForNumber: number?.rules?.maxLength,
    maximumCharactersForComplement: complement?.rules?.maxLength,
  }).messages as AddressMessages

  const { geographicalLevels } = addressMessages

  const geoCommercialConfig: GeoCommercialConfig = {
    level1: {
      id: 'level1',
      label: geographicalLevels.level1,
      placeholder: geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'level2',
      label: geographicalLevels.level2,
      placeholder: geographicalLevels.placeholders.level2,
    },
    level3: {
      id: 'level3',
      label: geographicalLevels.level3,
      placeholder: geographicalLevels.placeholders.level3,
    },
    level4: {
      id: 'level4',
      label: geographicalLevels.level4,
      placeholder: geographicalLevels.placeholders.level4,
    },
    requiredErrorMessage: addressMessages.addressRequiredError,
  }

  const formRules = { required: addressMessages.addressRequiredError }

  const {
    level1,
    level2,
    level3,
    level4,
    complement: complementValue,
    number: numberValue,
    references: referencesValue,
    street: streetValue,
    urbanizacion,
  } = watch()

  useEffect(() => {
    if (
      mustResetLevels &&
      (complementValue || numberValue || referencesValue || streetValue || urbanizacion)
    ) {
      reset({
        level1,
        level2,
        level3,
        level4,
        street: '',
        number: '',
        urbanizacion: '',
        references: '',
        complement: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level1, level2, level3, level4])

  return (
    <>
      <GeoCommercialStructure
        customConfig={geoCommercialConfig}
        countryId={CountryId.Peru}
        shouldNotResetOnce={shouldNotResetOnce}
        hasAsteriskInTheLabel
      />

      <GridContainer className="natds1" justify="space-between" spacing={1}>
        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledTextField
            id={LevelName.Street}
            label={addressMessages.mainStreet.label}
            control={control}
            rules={{
              ...formRules,
              maxLength: {
                message: addressMessages.mainStreet.errorMessage,
                value: street?.maxLength,
              },
            }}
            placeholder={addressMessages.placeholders.mainStreet}
            required
            shouldValidateIsNotOnlySpaces
          />
        </GridItem>
        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledAddressNumberField
            id={LevelName.Number}
            label={addressMessages.number.label}
            placeholder={addressMessages.placeholders.number}
            rules={{
              ...formRules,
              maxLength: {
                message: addressMessages.number.errorMessage,
                value: number?.rules.maxLength,
              },
            }}
            validChars={number && number.validChars}
            noNumberLabel={addressMessages.checkBoxWithoutNumber}
            hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
          />
        </GridItem>
      </GridContainer>

      <GridContainer className="natds1" justify="space-between" spacing={1}>
        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledTextField
            id={LevelName.Urbanizacion}
            label={addressMessages.neighborhood.label}
            control={control}
            rules={{
              ...formRules,
              maxLength: {
                message: addressMessages.neighborhood.errorMessage,
                value: neighborhood?.rules.maxLength,
              },
            }}
            placeholder={addressMessages.placeholders.neighborhood}
            required
            shouldValidateIsNotOnlySpaces
            noValueCheckbox
            noValueLabel={addressMessages.checkBoxWithoutUrbanizacion}
            validChars={neighborhood?.validChars}
          />
        </GridItem>
        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledTextField
            id={LevelName.Complement}
            label={addressMessages.complementaryData.label}
            control={control}
            placeholder={addressMessages.placeholders.complementaryData}
            noValueCheckbox
            noValueLabel={addressMessages.checkBoxWithoutComplementaryData}
            required
            rules={{
              ...formRules,
              maxLength: {
                message: addressMessages.complementaryData.errorMessage,
                value: complement?.rules.maxLength,
              },
            }}
            validChars={complement?.validChars}
          />
        </GridItem>
      </GridContainer>

      <ControlledTextField
        id={LevelName.References}
        label={addressMessages.references.label}
        control={control}
        rules={{
          ...formRules,
          maxLength: {
            message: addressMessages.references.errorMessage,
            value: reference.maxLength,
          },
        }}
        placeholder={addressMessages.placeholders.references}
        required
        shouldValidateIsNotOnlySpaces
      />
    </>
  )
}
