import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

import { deviceSize } from '@/utils/device-sizes'

const mediaQuery = `@media (max-width:${deviceSize.tablet}px)`
const mediaQueryGroupSector = '@media (min-width:1280px)'

export const useConsultantDataStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
  },
  editDiv: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  editIcon: {
    marginLeft: theme.spacing(1),
  },
  editButton: {
    display: 'flex',
    textTransform: 'uppercase',
  },
  typographyName: {
    paddingBottom: theme.spacing(0),
    textTransform: 'capitalize',
  },
  typographyNickname: {
    paddingBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
  typographyMessageNickname: {
    paddingBottom: theme.spacing(0.5),
    color: '#BBBBBB',
  },
  typographyMessageRacial: {
    marginTop: theme.spacing(1),
  },
  typographySector: {
    textTransform: 'capitalize',
  },
  divider: {
    [mediaQuery]: {
      display: 'none',
    },
  },
  groupSectorContainer: {
    display: 'flex',
    flexDirection: 'column',

    [mediaQueryGroupSector]: {
      flexDirection: 'row',
      gap: theme.spacing(3.8),
    },
  },
}))
